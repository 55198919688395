import '../assets/css/style.css';
import Nav from '../components/Nav';
import Hero from './Hero';

function Header() { 
    return(
        <div className='header'>
            <Nav></Nav>
            <Hero></Hero>
        </div>
    );
}
export default Header   