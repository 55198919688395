import "../assets/css/style.css";
import "../assets/css/ourPlatforms.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function OurPlatforms() {
  const [count, setCount] = useState(0);
  const [startCounting, setStartCounting] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const scrollPosition = window.scrollY;
      const triggerHeight = 1500; // Altura da página em pixels em que o contador deve começar

      if (scrollPosition >= triggerHeight) {
        setStartCounting(true);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (startCounting) {
      const targetCount = 532;
      const duration = 266; // Duração em milissegundos
      const increment = targetCount / duration;

      const interval = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount + increment >= targetCount) {
            clearInterval(interval);
            return targetCount;
          }
          return prevCount + increment;
        });
      }); // Intervalo de 10 milissegundos (ajuste conforme necessário)

      return () => clearInterval(interval);
    }
  }, [startCounting]);

  return (
    <div id="ourPlatforms">
      <div id="oP-title">
        <h1>
          Nossas
          <br />
          plataformas
        </h1>
        <div className="oP-btn">
          <button>
            <Link className="link" to="/platforms">
              Saber mais...
            </Link>
          </button>
        </div>
      </div>
      <div id="oP-arch">
        <div className="oP-arch-title">
          <h2>Intel Arch</h2>
          <p>
            Intel Arch é uma plataforma de logística automotiva. Ela gerencia a
            qualidade dos veículos e tem um portal de acompanhamento em tempo
            real com um dashboard personalizável.
          </p>
        </div>
      </div>
      <div id="oP-business">
        <h1>Qualitysin</h1>
        <p>
          O Qualitysin é uma plataforma que oferece gestão integrada e
          conformidade normativa para empresas. Ela é compatível com as normas
          ISO 9001, 14001, 45001, 27001, 28000, 37001 e OEA da Receita Federal
          Brasileira.
        </p>
      </div>
      <div className="count">
        <div className="count-content">
          <h1>+{count.toLocaleString()} Mil</h1>
          <p>Mais de meio milhão de veículos gerenciados</p>
        </div>
      </div>
      <div className="oP-img">
        <img
          className="oP-img1"
          src="https://singlec.com.br/imagens-site/intel_arch.png"
          alt="Intel Ach"
          height={"310px"}
          width={"500px"}
        />
        <img
          className="oP-img2"
          src="https://singlec.com.br/imagens-site/qualitysin.png"
          alt="Qualitysin"
          height={"310px"}
          width={"500px"}
        />
      </div>
    </div>
  );
}
export default OurPlatforms;
