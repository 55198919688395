import "../assets/css/style.css";
import "../assets/css/platformsHeader.css";
import arrow from "../assets/icon/icon_arrow.png";

function PlatformsHeader() {
  return (
    <div id="platformsHeader">
      <div id="particle6"></div>
      <div id="particle7"></div>
      <div className="platformsHeader-title">
        <h1>
          Saiba tudo sobre
          <br />
          as plataformas da Singlec
        </h1>
        <div className="underline2"></div>
      </div>
      <div className="platformsHeader-img">
        <img
          src="https://singlec.com.br/imagens-site/hero_plataforma.png"
          alt=""
          width={"850px"}
        />
      </div>
      <div className="arrows">
        <div className="arrow">
          <a href="#arch">
            <img src={arrow} width={"50px"} height={"50px"} alt="" />
          </a>
        </div>
        <div className="arrow2">
          <a href="#arch">
            <img src={arrow} width={"50px"} height={"50px"} alt="" />
          </a>
        </div>
        <div className="arrow3">
          <a href="#arch">
            <img src={arrow} width={"50px"} height={"50px"} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}
export default PlatformsHeader;
