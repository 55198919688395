export function activeBtn(btnId) {
  var button = document.querySelector(`#questionBtn${btnId}`);
  var question = button.closest(".question");
  var card = question.closest(".card");
  var btn = button.querySelector(".btnIcon");
  var number = question.querySelector(".number");
  var answer = question.nextElementSibling;

  question.classList.toggle("active");
  card.classList.toggle("active");
  btn.classList.toggle("active");
  answer.classList.toggle("active");
  number.classList.toggle("active");
}
