export function activeSlide(setSlideState1, setSlideState2, setSlideState3){
    if(window.scrollY > 1150){
        setSlideState1('contextSlide1');
        setSlideState2('contextSlide2');
        setSlideState3('contextSlide3');
      }
      else{
        setSlideState1('');
        setSlideState2('');
        setSlideState3('');
      }
    }

export function activeSlide2(setSlideState1, setSlideState2, setSlideState3, setSlideState4){
  if(window.scrollY > 310){
      setSlideState1('ourServicesSlide1');
      setSlideState2('ourServicesSlide2');
      setSlideState3('ourServicesSlide3');
      setSlideState4('ourServicesSlide4');
  }
  else{
      setSlideState1('');
      setSlideState2('');
      setSlideState3('');
      setSlideState4('');
  }
}

export function activeSlide3(setSlideState1, setSlideState2, setSlideState3, setSlideState4){
  if(window.scrollY > 4220){
      setSlideState1('whySlide1');
      setSlideState2('whySlide2');
      setSlideState3('whySlide3');
      setSlideState4('whySlide4');
    }
    else{
      setSlideState1('');
      setSlideState2('');
      setSlideState3('');
      setSlideState4('');
    }
  }

export function activeSlide5(setSlideState1, setSlideState2, setSlideState3) {
  const windowHeight = window.innerHeight;
  const documentHeight = document.documentElement.scrollHeight;
  const scrollPosition = window.scrollY || window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop;

  if (scrollPosition + windowHeight >= documentHeight) {
    setSlideState1('instaLogoAfter');
    setSlideState2('wappLogoAfter');
    setSlideState3('linkLogoAfter');
  } else {
    setSlideState1('instaLogo');
    setSlideState2('wappLogo');
    setSlideState3('linkLogo');
  }
}
  
