import "../assets/css/style.css";
import "../assets/css/servicesHeader.css";
import arrow from "../assets/icon/icon_arrow.png";

function ServicesHeader() {
  return (
    <div id="servicesHeader">
      <div id="particle4"></div>
      <div id="particle5"></div>
      <div className="servicesHeader-title">
        <h1>
          Saiba mais sobre os
          <br />
          nossos serviços!
        </h1>
        <div className="underline2"></div>
      </div>
      <div className="servicesHeader-img">
        <img
          src="https://singlec.com.br/imagens-site/services.png"
          width={"850px"}
          alt=""
        />
      </div>
      <div className="arrows">
        <div className="arrow">
          <a href="#allServices">
            <img src={arrow} width={"50px"} height={"50px"} alt="" />
          </a>
        </div>
        <div className="arrow2">
          <a href="#allServices">
            <img src={arrow} width={"50px"} height={"50px"} alt="" />
          </a>
        </div>
        <div className="arrow3">
          <a href="#allServices">
            <img src={arrow} width={"50px"} height={"50px"} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}
export default ServicesHeader;
