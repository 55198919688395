import "../../assets/css/style.css";
import Header from "../../layout/Header.jsx";
import OurServices from "../../layout/OurServices.jsx";
import Footer from "../../components/footer.jsx";
import OurPlatforms from "../../layout/OurPlatforms.jsx";
import Why from "../../layout/Why.jsx";
import OurMedias from "../../layout/OurMedias.jsx";
import Budget from "../../layout/Budget.jsx";
import Particles from "../../layout/Particles.jsx";
import Buttons from "../../components/Buttons.jsx";

function Home() {
  return (
    <div className="home">
      <Buttons></Buttons>
      <Particles></Particles>
      <Header></Header>
      <OurServices></OurServices>
      <OurPlatforms></OurPlatforms>
      <OurMedias></OurMedias>
      <Why></Why>
      <Budget></Budget>
      <Footer></Footer>
    </div>
  );
}
export default Home;
