import "../assets/css/style.css";
import "../assets/css/ourServices.css";
import { activeSlide2 } from "../assets/js/activeSlide";
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
function OurServices() {
  const [slideState1, setSlideState1] = useState("");
  const [slideState2, setSlideState2] = useState("");
  const [slideState3, setSlideState3] = useState("");
  const [slideState4, setSlideState4] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", () =>
      activeSlide2(
        setSlideState1,
        setSlideState2,
        setSlideState3,
        setSlideState4
      )
    );
    return () =>
      window.removeEventListener("scroll", () =>
        activeSlide2(
          setSlideState1,
          setSlideState2,
          setSlideState3,
          setSlideState4
        )
      );
  }, []);

  return (
    <div id="ourServices">
      <div className="ourServices-text">
        <h1>Nossos serviços</h1>
        <p>
          Nossa oferta abrange uma ampla gama de serviços para atender às
          diversas demandas de sua empresa, ultrapassando as expectativas de uma
          mera provedora de tecnologia.
        </p>
        <div className="ourServices-btn">
          <button>
            <Link className="link" to="/services">
              Saber mais...
            </Link>
          </button>
        </div>
      </div>
      <div id="border" className={slideState1}>
        <div className="ourServices-cards">
          <div id="os-card-1" className={slideState2}>
            <h2>Desenvolvimento de softwares e sites</h2>
            <p>
              Sabemos que sua ideia para impulsionar seu negócio não pode
              esperar. Por isso, estamos prontos para desenvolvê-la utilizando
              uma ampla gama de ferramentas e habilidades de desenvolvimento.
            </p>
          </div>
          <div id="os-card-2" className={slideState3}>
            <h2>Marketing Digital</h2>
            <p>
              Aqui, nosso marketing digital transcende a simples criação de
              artes. Cuidamos de toda a visibilidade da sua empresa no mercado.
              Que tal mais vendas e reconhecimento?
            </p>
          </div>
          <div id="os-card-3" className={slideState4}>
            <h2>Gerenciamento de provedor de e-mail</h2>
            <p>
              Oferecemos serviços abrangentes, incluindo gerenciamento de
              e-mail, criação de domínio, adição/exclusão de usuários e suporte
              para questões relacionadas a pagamentos de anuidade e muito mais.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OurServices;
