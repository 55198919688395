import "../assets/css/style.css";
import "../assets/css/buttons.css";
import { activeSlide5 } from "../assets/js/activeSlide";
import { useState, useEffect } from "react";

function Buttons() {
  const [slideState1, setSlideState1] = useState("instaLogo");
  const [slideState2, setSlideState2] = useState("wappLogo");
  const [slideState3, setSlideState3] = useState("linkLogo");

  useEffect(() => {
    window.addEventListener("scroll", () =>
      activeSlide5(setSlideState1, setSlideState2, setSlideState3)
    );
    return () =>
      window.removeEventListener("scroll", () =>
        activeSlide5(setSlideState1, setSlideState2, setSlideState3)
      );
  }, []);

  return (
    <div id="buttons">
      <div className={slideState1}>
        <a
          href="https://www.instagram.com/singlec_oficial/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://singlec.com.br/imagens-publicas/instagram.png"
            style={{ width: "25px", height: "25px" }}
            alt=""
          />
        </a>
      </div>
      <div className={slideState2}>
        <a
          href="https://api.whatsapp.com/send?phone=+55%2047%209989-4755&text=Ol%C3%A1%2C+estou+entrando+em+contato+atrav%C3%A9s+do+site+da+Singlec+e+gostaria+de+saber+mais+sobre+os+servi%C3%A7os+dispon%C3%ADveis.+Pode+me+ajudar%3F"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://singlec.com.br/imagens-publicas/whatsapp.png"
            style={{ width: "25px", height: "25px" }}
            alt=""
          />
        </a>
      </div>
      <div className={slideState3}>
        <a
          href="https://www.linkedin.com/company/singlec/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://singlec.com.br/imagens-publicas/linkedin.png"
            style={{ width: "25px", height: "25px" }}
            alt=""
          />
        </a>
      </div>
    </div>
  );
}
export default Buttons;
