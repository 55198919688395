import "../assets/css/faqHeader.css";
import arrow from "../assets/icon/icon_arrow.png";

function FaqHeader() {
  return (
    <div id="faqHeader">
      <div id="particle9"></div>
      <div id="particle10"></div>
      <div id="particle11"></div>
      <div className="faqHeader-title">
        <h1>
          Está com duvidas? <br />{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Conheça o nosso
          FAQ
        </h1>
        <div className="underline2 underlineFaq"></div>
      </div>
      <div className="faqHeader-img">
        <img
          src="https://singlec.com.br/imagens-site/faq.png"
          alt=""
          width={"650px"}
        />
      </div>
      <div className="arrows">
        <div className="arrow">
          <a href="#questions">
            <img src={arrow} width={"50px"} height={"50px"} alt="" />
          </a>
        </div>
        <div className="arrow2">
          <a href="#questions">
            <img src={arrow} width={"50px"} height={"50px"} alt="" />
          </a>
        </div>
        <div className="arrow3">
          <a href="#questions">
            <img src={arrow} width={"50px"} height={"50px"} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}
export default FaqHeader;
