import "../assets/css/style.css";
import "../assets/css/budget.css";
import React, { useState } from "react";
import { api } from "../config/api";
import { useEmailSent } from "../context/EmailSentContext";

function Budget() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isEmailResponseLoading, setIsEmailResponseLoading] = useState(false);

  const { setShouldShowModal } = useEmailSent();

  function phoneAuth(event) {
    const inputPhone = event.target.value;
    const formattedPhone = inputPhone
      .replace(/\D/g, "") // Remove todos os caracteres não numéricos
      .replace(/^(\d{2})(\d)/g, "($1) $2") // Adiciona parênteses em volta dos dois primeiros dígitos
      .replace(/(\d)(\d{4})$/, "$1-$2"); // Adiciona hífen entre o quinto e o sexto dígitos
    setPhone(formattedPhone);
  }
  const handleFormSubmit = async (event) => {
    try {
      setIsEmailResponseLoading(true);

      await api.post(`/sendEmail`, {
        name,
        email,
        phone,
        message,
        company: "Singlec",
      });

      setShouldShowModal(true);

      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
    } catch (error) {
      console.log(error);
    } finally {
      setIsEmailResponseLoading(false);
    }
  };
  return (
    <>
      <div id="budget">
        <div id="budget-content">
          <div id="budget-title">
            <h1>Faça o seu orçamento</h1>
            <p>
              Envie-nos uma mensagem e nossa equipe entrará em contato com você
            </p>
          </div>
          <form id="budget-form" method="POST">
            <div className="alert">
              <p>{alert}</p>
            </div>
            <div id="form-item">
              <input
                id="nome"
                type="text"
                className="input"
                value={name}
                placeholder="Nome"
                autoComplete="off"
                maxLength={80}
                minLength={3}
                onChange={(event) => setName(event.target.value)}
              />
              <label className="label" htmlFor="nome"></label>
            </div>
            <div id="form-item">
              <input
                id="email"
                type="email"
                name="email"
                className="input"
                placeholder="E-mail"
                value={email}
                autoComplete="off"
                maxLength={60}
                onChange={(event) => setEmail(event.target.value)}
              />
              <label className="label" htmlFor="email"></label>
            </div>
            <div id="form-item">
              <input
                id="phone"
                type="phone"
                name="phone"
                className="input"
                placeholder="Telefone"
                value={phone}
                autoComplete="off"
                maxLength={15}
                minLength={15}
                onChange={phoneAuth}
              />
              <label className="label" htmlFor="phone"></label>
            </div>
            <div id="form-item">
              <input
                id="message"
                type="text"
                name="message"
                className="input input-message"
                placeholder="Mensagem"
                value={message}
                autoComplete="off"
                maxLength={300}
                minLength={25}
                as="text-area"
                onChange={(event) => setMessage(event.target.value)}
              />
              <label className="label" htmlFor="message"></label>
            </div>
            <button
              type="button"
              onClick={handleFormSubmit}
              disabled={isEmailResponseLoading}
              className="form-btn"
            >
              {isEmailResponseLoading ? "Enviando..." : "Enviar"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
export default Budget;
