import "../assets/css/style.css";
import "../assets/css/policies.css";

function Policies() {
  return (
    <div id="policies">
      <div id="policies-title">
        <h1>Nossas Políticas</h1>
        <div className="underline"></div>
        <p>
          Conheça nossas políticas, aquelas que são utilizadas como foco das
          tomadas de decisão internas, com base nelas estruturamos nossa
          empresas e alinhamos nossa equipe. Sendo:
        </p>
      </div>
      <div id="policies-cards">
        <div className="p-card p-card1">
          <h2>Política Ambiental</h2>
          <p>
            Comprometemo-nos a preservar o meio ambiente, promovendo a
            sustentabilidade e assegurando a destinação responsável de resíduos.
            Assim como concentrar nossos esforços em garantir que nossas
            tecnologias sejam impulsionadoras de avanços e práticas de controle
            ambiental.
          </p>
        </div>
        <div className="p-card p-card2">
          <h2>
            Política de Segurança da
            <br />
            Informação
          </h2>
          <p>
            Garantir o direito de liberdade de expressão e de privacidade de
            qualquer usuário das nossas tecnologias. Utilizar e desenvolver
            ferramentas de auxílio a questões Sociais, Ambientais e de
            Governança e estabelecer normas e processos internos que garantam a
            conformidade com os requisitos legais aplicados.
          </p>
        </div>
        <div className="p-card p-card3">
          <h2>Política de Qualidade</h2>
          <p>
            Nosso compromisso é proporcionar experiências excepcionais aos
            nossos usuários, integrando de forma harmoniosa o design e as
            funcionalidades operacionais. Buscando a inovação e melhoria
            contínua em nossos sistemas e plataformas para aprimorar
            constantemente a experiência dos nossos clientes. Valorizando o
            compartilhamento de conhecimento como alicerce para o aprimoramento
            de técnicas e a criação de novas tecnologias para o mercado.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Policies;
