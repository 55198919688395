import React from 'react'
import style from '../assets/css/emailmodal.module.css'
import { useEmailSent } from '../context/EmailSentContext'

const EmailSentModal = () => {

    const {shouldShowModal} = useEmailSent()

    if(!shouldShowModal){
        return null
    }

    return (
        <div className={style.content}>
            <p className={style.text}>Email enviado com sucesso!</p>
        </div>
    )
}

export default EmailSentModal