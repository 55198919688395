import "../assets/css/footer.css";
import "../assets/css/style.css";

function Footer() {
  return (
    <footer>
      <div id="footer-logo">
        <img
          src="https://singlec.com.br/imagens-publicas/logo_deitada_branca.png"
          width={180}
          height={"30px"}
          alt=""
        />
      </div>
      <div id="footer-text">
        <p>
          Singlec Pesquisa e Desenvolvimento de Inteligência Artificial LTDA
        </p>
        <p>44.380.630/0001-04</p>
      </div>
      <div id="footer-logo2"></div>
    </footer>
  );
}
export default Footer;
