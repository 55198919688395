import '../assets/css/particles.css';
import '../assets/css/style.css';

function Particles(){
    return(
        <div id='particles'>
            <div className='particle1'></div>
            <div className='particle2'></div>
            <div className='particle3'></div>
            <div className='particle4'></div>
            <div className='particle5'></div>
            <div className='particle6'></div>
            <div className='particle7'></div>
            <div className='particle8'></div>
            <div className='particle9'></div>
            <div className='particle10'></div>
            <div className='particle11'></div>
            <div className='particle12'></div>
            <div className='particle13'></div>
            <div className='particle14'></div>
            <div className='particle15'></div>
            <div className='particle16'></div>
            <div className='particle17'></div>
            <div className='particle18'></div>
            <div className='particle19'></div>
            <div className='particle20'></div>
            <div className='particle21'></div>
            <div className='particle22'></div>
            <div className='particle23'></div>
            <div className='particle24'></div>
            <div className='particle25'></div>
            <div className='particle26'></div>
            <div className='particle27'></div>
            <div className='particle28'></div>
            <div className='particle29'></div>
            <div className='particle30'></div>
            <div className='particle31'></div>
            <div className='particle32'></div>
            <div className='particle33'></div>
            <div className='particle34'></div>
            <div className='particle35'></div>
            <div className='particle36'></div>
            <div className='particle37'></div>
            <div className='particle38'></div>
            <div className='particle39'></div>
            <div className='particle40'></div>
            <div className='particle41'></div>
            <div className='particle42'></div>
        </div>
    )
}
export default Particles;