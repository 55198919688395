import "../assets/css/style.css";
import "../assets/css/history.css";
import poster from "../assets/img/aboutIMG/videoposter.png";
import video from "../assets/video/singlec_sobre.mp4";

function History() {
  return (
    <div id="history">
      <div id="border-title">
        <h1>Sobre nós...</h1>
      </div>
      <div className="align-about">
        <div id="border-text">
          <p>
            Somos uma empresa determinada, comprometida com nossos objetivos e
            sempre em busca de aprendizado contínuo. A Singlec almeja ser
            referência no desenvolvimento de software e sites, uma meta que
            perseguimos ao longo de nossa sólida trajetória neste setor. Temos a
            convicção de que nossos serviços são diferenciados, e nos esforçamos
            ao máximo para superar as expectativas.
            <br />
            Com dedicação inabalável, trabalhamos incessantemente para minimizar
            erros nos processos, proporcionando agilidade aos colaboradores da
            sua empresa. Nosso compromisso vai além dos limites da nossa
            organização; buscamos não apenas fazer a diferença em nosso próprio
            contexto, mas também contribuir positivamente para a sua empresa.
          </p>
        </div>
        <div id="video-border">
          <div className="background-video">
            <div id="history-video">
              <video
                src={video}
                controls
                controlsList="nodownload"
                poster={poster}
              ></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default History;
