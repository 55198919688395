import "../assets/css/style.css";
import "../assets/css/allServices.css";

function AllServices() {
  return (
    <div id="allServices">
      <div className="service1 service">
        <div className="service1-img service-img">
          <img
            className="tablet"
            src="https://singlec.com.br/imagens-site/tablet.png"
            alt=""
          />
          <img
            className="phone"
            src="https://singlec.com.br/imagens-site/phone.png"
            alt=""
          />
          <img
            className="laptop"
            src="https://singlec.com.br/imagens-site/laptop.png"
            alt=""
          />
        </div>
        <div className="timeline"></div>
        <div className="service1-text service-text">
          <div className="service1-title service-title">
            <h2 className="item">1</h2>
            <h1>Desenvolvimento de softwares</h1>
          </div>
          <p>
            Somos especializados no desenvolvimento de softwares, buscando
            incessantemente proporcionar a melhor experiência aos nossos
            clientes. Desenvolvemos soluções personalizadas para otimizar as
            operações do seu negócio, simplificando e acelerando a gestão de
            cada aspecto da sua empresa.
          </p>
        </div>
      </div>
      <div className="service2 service">
        <div className="timeline"></div>
        <div className="service2-text service-text">
          <div className="service2-title service-title">
            <h1>Desenvolvimento de sites</h1>
            <h2 className="item">2</h2>
          </div>
          <p>
            Quando falamos de sites que vão além do comum, proporcionando a cada
            pessoa uma experiência verdadeiramente única, a Singlec é a
            referência. Desde o início da nossa trajetória, dedicamo-nos à
            criação de sites que destacam verdadeiramente o potencial dos nossos
            clientes
          </p>
        </div>
        <div className="service2-img service-img">
          <img
            className="site1"
            src="https://singlec.com.br/imagens-site/site_palangana.png"
            alt=""
          />
          <img
            className="site2"
            src="https://singlec.com.br/imagens-site/site_singlec.png"
            alt=""
          />
          <img
            className="laptopSites"
            src="https://singlec.com.br/imagens-site/capa_site.png"
            alt=""
          />
        </div>
      </div>
      <div className="service3 service">
        <div className="timeline"></div>
        <div className="service3-img service-img">
          <img
            className="phoneMarketing1"
            src="https://singlec.com.br/imagens-site/phone_marketing_normung.png"
            alt=""
          />
          <img
            className="phoneMarketing2"
            src="https://singlec.com.br/imagens-site/phone_marketing_palangana.png"
            alt=""
          />
          <img
            className="phoneMarketing3"
            src="https://singlec.com.br/imagens-site/phone_marketing_singlec.png"
            alt=""
          />
        </div>
        <div className="service3-text service-text">
          <div className="service3-title service-title">
            <h2 className="item">3</h2>
            <h1>Marketing Digital</h1>
          </div>
          <p>
            Cada empresa é única, e a preservação e cuidado da sua identidade
            visual são essenciais. Na Singlec, oferecemos uma estratégia de
            marketing digital que coloca a sua marca em destaque para o seu
            público-alvo.
          </p>
        </div>
      </div>
      <div className="service4 service">
        <div className="timeline"></div>
        <div className="service4-text service-text">
          <div className="service4-title service-title">
            <h1>Gerenciamento de provedor de e-mail</h1>
            <h2 className="item">4</h2>
          </div>
          <p>
            Contar com um provedor de e-mail hoje é indispensável, e ter
            assistência para questões como a criação de domínio, adição ou
            exclusão de usuários, suporte no pagamento de anuidades e demais
            preocupações é essencial. Com o diferenciado serviço de provedor de
            e-mail da Singlec, você não precisa mais se preocupar com esses
            detalhes, permitindo que direcione sua atenção para o que realmente
            importa para o sucesso da sua empresa
          </p>
        </div>
        <div className="service4-img service-img">
          <img
            className="email"
            src="https://singlec.com.br/imagens-site/email.png"
            alt=""
          />
          <img
            className="email2"
            src="https://singlec.com.br/imagens-site/tablet.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
export default AllServices;
