import React, { useEffect, useState, useRef } from "react";
import "../assets/css/hero.css";
import "../assets/css/particles.css";
import arrow from "../assets/icon/icon_arrow.png";
import { useCallback } from "react";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import video from "../assets/video/homeVideo.mp4";

function Hero() {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    // Verifica se o dispositivo é um iOS
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    if (isIOS && videoElement) {
      // Desativa os controles nativos no iOS
      videoElement.setAttribute("controls", "false");
    }
  }, []);
  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {}, []);

  const particleOptions = {
    background: {
      color: {
        value: "transparent",
      },
    },
    position: "relative",
    fpsLimit: 150,
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: "push",
        },
        onHover: {
          enable: true,
          mode: "repulse",
        },
        resize: true,
      },
      modes: {
        push: {
          quantity: 4,
        },
        repulse: {
          distance: 200,
          duration: 0.1,
        },
      },
    },
    particles: {
      color: {
        value: "#3687c4",
      },
      links: {
        color: "#3687c4",
        distance: 140,
        enable: true,
        opacity: 0.7,
        width: 1,
      },
      move: {
        direction: "none",
        enable: true,
        outModes: {
          default: "bounce",
        },
        random: false,
        speed: 2,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 800,
        },
        value: 80,
      },
      opacity: {
        value: 0.5,
      },
      shape: {
        type: "circle",
      },
      size: {
        value: { min: 1, max: 5 },
      },
    },
    detectRetina: true,
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const particlesStyle = {
    transition: "opacity 0.5s ease",
    opacity: scrollPosition > 150 ? 0 : 1,
  };

  return (
    <div className="hero">
      <video
        autoPlay
        muted
        loop
        playsInline
        controlsList="disabled"
        src={video}
        preload="auto"
      ></video>
      <div className="particulas-container">
        <div className="particles-container" style={particlesStyle}>
          <Particles
            init={particlesInit}
            loaded={particlesLoaded}
            options={particleOptions}
          />
        </div>
      </div>
      <div className="hero-cont">
        <h1>
          Pesquisa e <br /> desenvolvimento de <br /> inteligência artificial
        </h1>
        <p>
          A tecnologia a serviço dos avanços ambientais, sociais e de
          governança.{" "}
        </p>
      </div>
      <div className="arrow">
        <a href="#ourServices">
          <img src={arrow} width={"50px"} height={"50px"} alt="" />
        </a>
      </div>
      <div className="arrow2">
        <a href="#ourServices">
          <img src={arrow} width={"50px"} height={"50px"} alt="" />
        </a>
      </div>
      <div className="arrow3">
        <a href="#ourServices">
          <img src={arrow} width={"50px"} height={"50px"} alt="" />
        </a>
      </div>
    </div>
  );
}
export default Hero;
