import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./view/home/index.jsx";
import About from "./view/about/index.jsx";
import Services from "./view/services/index.jsx";
import Platforms from "./view/platforms/index.jsx";
import FAQ from "./view/faq/index.jsx";
import SAC from "./view/sac/index.jsx";
import { EmailSentProvider } from "./context/EmailSentContext.jsx";
import EmailSentModal from "./components/EmailSentModal.jsx";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // Rola para o topo da página sempre que a rota mudar
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <ScrollToTop />{" "}
      {/* Isso garantirá que a página seja rolada para o topo quando a navegação ocorrer */}
      <EmailSentProvider>
        <Routes>
          <Route exact path="/" name="Home" element={<Home />} />
          <Route path="/about" name="About" element={<About />} />
          <Route path="/services" name="Services" element={<Services />} />
          <Route path="/platforms" name="Platforms" element={<Platforms />} />
          <Route path="/faq" name="FAQ" element={<FAQ />} />
          <Route path="/sac" name="SAC" element={<SAC />} />
        </Routes>
        <EmailSentModal />
      </EmailSentProvider>
    </React.Suspense>
  );
}

export default App;
