import "../../assets/css/style.css";
import Nav from "../../components/Nav";
import ServicesHeader from "../../layout/ServicesHeader";
import Footer from "../../components/footer";
import Buttons from "../../components/Buttons";
import AllServices from "../../layout/AllServices";

function Services() {
  return (
    <div className="services">
      <Nav></Nav>
      <ServicesHeader></ServicesHeader>
      <AllServices></AllServices>
      <Footer></Footer>
      <Buttons></Buttons>
    </div>
  );
}
export default Services;
