import "../assets/css/questions.css";
import { activeBtn } from "../assets/js/faq";
import arrow from "../assets/icon/icon_arrowBlue.png";

function Questions() {
  return (
    <div id="questions">
      <div className="questionsTitle">
        <h1>Perguntas Frequentes</h1>
        <div className="underline2 underlineQuestions"></div>
      </div>
      <div className="questionsCards">
        <div className="card1 card">
          <div className="question">
            <div className="number">
              <h2>1</h2>
            </div>
            <h1>Como faço para contratar a plataforma QualitySin?</h1>
            <button
              onClick={() => activeBtn(1)}
              id="questionBtn1"
              className="questionBtn"
            >
              <img className="btnIcon" src={arrow} width={"30px"} alt="" />
            </button>
          </div>
          <div className="answer">
            <p>
              Para adquirir o QualitySin e aproveitar todos os benefícios que
              nossa plataforma oferece, é simples. Tudo o que você precisa fazer
              é entrar em contato com a nossa equipe de vendas. Eles estarão
              prontos para lhe fornecer todas as informações necessárias,
              responder às suas perguntas e orientá-lo através do processo de
              aquisição. Estamos aqui para tornar o processo o mais conveniente
              possível para você.
            </p>
          </div>
        </div>
        <div className="card2 card">
          <div className="question">
            <div className="number">
              <h2>2</h2>
            </div>
            <h1>
              Como faço para adquirir o teste grátis da plataforma QualitySin?
            </h1>
            <button
              onClick={() => activeBtn(2)}
              id="questionBtn2"
              className="questionBtn"
            >
              <img className="btnIcon" src={arrow} width={"30px"} alt="" />
            </button>
          </div>
          <div className="answer">
            <p>
              Se você deseja experimentar a plataforma QualitySin gratuitamente
              antes de se comprometer, é uma excelente escolha. Para obter o
              teste gratuito, basta entrar em contato com nossa equipe e
              informar que você está interessado em iniciar o período de
              avaliação. Estaremos felizes em providenciar seu acesso ao teste,
              permitindo que você descubra como nossa plataforma pode atender às
              suas necessidades sem custo algum.
            </p>
          </div>
        </div>
        <div className="card3 card">
          <div className="question">
            <div className="number">
              <h2>3</h2>
            </div>
            <h1>Como adicionar um novo módulo à plataforma?</h1>
            <button
              onClick={() => activeBtn(3)}
              id="questionBtn3"
              className="questionBtn"
            >
              <img className="btnIcon" src={arrow} width={"30px"} alt="" />
            </button>
          </div>
          <div className="answer">
            <p>
              Caso você deseje expandir as funcionalidades do sistema e
              adicionar um novo módulo, estamos prontos para ajudar. Basta
              entrar em contato com nossa equipe, explicar qual módulo adicional
              você gostaria de integrar ao seu sistema, e nós cuidaremos do
              resto. Nossa equipe de suporte técnico estará à disposição para
              garantir uma integração perfeita e proporcionar a você as
              ferramentas necessárias para otimizar ainda mais seus processos.
            </p>
          </div>
        </div>
        <div className="card4 card">
          <div className="question">
            <div className="number">
              <h2>4</h2>
            </div>
            <h1>Quais as formas de pagamento para adesão ao QualitySin?</h1>
            <button
              onClick={() => activeBtn(4)}
              id="questionBtn4"
              className="questionBtn"
            >
              <img className="btnIcon" src={arrow} width={"30px"} alt="" />
            </button>
          </div>
          <div className="answer">
            <p>
              O processo de adesão ao QualitySin geralmente envolve a assinatura
              de um contrato de serviços. Este contrato especificará os termos e
              condições, incluindo a periodicidade do pagamento, os detalhes de
              faturamento e outras informações importantes relacionadas ao uso
              da plataforma. Após a assinatura do contrato, a forma de pagamento
              padrão é mensal por meio de boleto bancário. Você receberá um
              boleto mensalmente para efetuar o pagamento, facilitando o
              controle financeiro da sua organização.
            </p>
          </div>
        </div>
        <div className="card5 card">
          <div className="question">
            <div className="number">
              <h2>5</h2>
            </div>
            <h1>Como posso fazer o cancelamento da plataforma?</h1>
            <button
              onClick={() => activeBtn(5)}
              id="questionBtn5"
              className="questionBtn"
            >
              <img className="btnIcon" src={arrow} width={"30px"} alt="" />
            </button>
          </div>
          <div className="answer">
            <p>
              Entendemos que as necessidades de negócios podem mudar, e estamos
              aqui para tornar o processo de cancelamento o mais simples
              possível. Para solicitar o cancelamento da plataforma, entre em
              contato com nossa equipe de suporte ou atendimento ao cliente.
              Eles guiarão você pelos procedimentos necessários e garantirão que
              seu pedido seja tratado com eficiência e em conformidade com
              nossas políticas.
            </p>
          </div>
        </div>
        <div className="card6 card">
          <div className="question">
            <div className="number">
              <h2>6</h2>
            </div>
            <h1>
              Como entrar em contato com o suporte técnico em caso de problemas
              ou dúvidas?
            </h1>
            <button
              onClick={() => activeBtn(6)}
              id="questionBtn6"
              className="questionBtn"
            >
              <img className="btnIcon" src={arrow} width={"30px"} alt="" />
            </button>
          </div>
          <div className="answer">
            <p>
              Nossa equipe de suporte técnico está pronta para auxiliá-lo em
              caso de qualquer problema ou dúvida que você possa encontrar. Você
              pode entrar em contato conosco por e-mail, utilizando o endereço
              suporte@singlec.com.br. Nossa equipe de suporte responderá
              prontamente para resolver seus problemas e fornecer a assistência
              de que você precisa para aproveitar ao máximo nossa plataforma.
            </p>
          </div>
        </div>
        <div className="card7 card">
          <div className="question">
            <div className="number">
              <h2>7</h2>
            </div>
            <h1>Consigo fazer download de uma não conformidade?</h1>
            <button
              onClick={() => activeBtn(7)}
              id="questionBtn7"
              className="questionBtn"
            >
              <img className="btnIcon" src={arrow} width={"30px"} alt="" />
            </button>
          </div>
          <div className="answer">
            <p>
              Sim, é possível fazer o download de uma não conformidade. Ao
              visualizar uma não conformidade, você terá a opção de baixá-la.
              Essa funcionalidade foi projetada para facilitar o gerenciamento
              de registros.
            </p>
          </div>
        </div>
        <div className="card8 card">
          <div className="question">
            <div className="number">
              <h2>8</h2>
            </div>
            <h1>Posso cadastrar quantos usuários?</h1>
            <button
              onClick={() => activeBtn(8)}
              id="questionBtn8"
              className="questionBtn"
            >
              <img className="btnIcon" src={arrow} width={"30px"} alt="" />
            </button>
          </div>
          <div className="answer">
            <p>
              Não impomos limites ao número de usuários que você pode cadastrar
              na plataforma QualitySin. Isso significa que você pode adicionar
              quantos usuários forem necessários para atender às demandas do seu
              negócio. Nossa flexibilidade nesse aspecto permite que você escale
              sua equipe de acordo com as necessidades da sua organização.
            </p>
          </div>
        </div>
        <div className="card9 card">
          <div className="question">
            <div className="number">
              <h2>9</h2>
            </div>
            <h1>
              Quantos usuários podem acessar a plataforma simultaneamente?
            </h1>
            <button
              onClick={() => activeBtn(9)}
              id="questionBtn9"
              className="questionBtn"
            >
              <img className="btnIcon" src={arrow} width={"30px"} alt="" />
            </button>
          </div>
          <div className="answer">
            <p>
              Não há restrições quanto ao número de usuários que podem acessar a
              plataforma QualitySin simultaneamente. Nossa infraestrutura é
              dimensionada para lidar com várias conexões simultâneas,
              garantindo que sua equipe possa colaborar de maneira eficaz e sem
              interrupções.
            </p>
          </div>
        </div>
        <div className="card10 card">
          <div className="question">
            <div className="number">
              <h2>10</h2>
            </div>
            <h1>Existe um limite de não conformidades cadastradas?</h1>
            <button
              onClick={() => activeBtn(10)}
              id="questionBtn10"
              className="questionBtn"
            >
              <img className="btnIcon" src={arrow} width={"30px"} alt="" />
            </button>
          </div>
          <div className="answer">
            <p>
              Não, não há limite para a quantidade de não conformidades que
              podem ser cadastradas na plataforma. Você tem total liberdade para
              registrar todas as não conformidades que encontrar, garantindo que
              sua organização tenha um controle completo e abrangente das
              questões relacionadas à conformidade. Isso ajuda a promover a
              transparência e a eficiência em seus processos de gestão da
              qualidade.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Questions;
