import "../assets/css/style.css";
import "../assets/css/arch.css";

function Arch() {
  return (
    <div id="arch">
      <div className="arch-title">
        <h1>Intel Arch</h1>
        <div className="underline3"></div>
        <h2>Tenha uma plataforma que atenda todas as suas necessidades</h2>
        <p>
          O Intel Arch representa uma solução abrangente para o controle
          logístico automotivo, qualidade e gestão de pátio em operações
          portuárias. Proporcionamos uma operação mais ágil e segura por meio de
          nossa avançada plataforma de última geração. Em tempo real, é possível
          visualizar a operação através de gráficos personalizáveis, adaptados
          às necessidades específicas de cada operação, resultando em uma
          execução mais eficiente.
        </p>
        <p>
          Atualmente, operamos no porto de Paranaguá, PR, e já tivemos presença
          bem-sucedida no porto de Itajaí, SC, com mais de meio milhão de
          veículos gerenciados
        </p>
      </div>
      <div className="arch-img1">
        <img
          src="https://singlec.com.br/imagens-site/plataforma_intel_arch.png"
          alt=""
          width={"650px"}
        />
      </div>
      <div className="arch-img2">
        <img
          src="https://singlec.com.br/imagens-site/navio.png"
          alt=""
          width={"650px"}
          height={"400px"}
        />
      </div>
      <div className="arch-text">
        <p>
          Oferecemos total integração com sistemas diversos e suporte para todas
          as montadoras. A flexibilidade da nossa plataforma permite ajustes
          para atender às demandas específicas da sua operação, garantindo uma
          solução personalizada e eficaz.
        </p>
        <br />
        <p>
          Destacamos nosso suporte altamente personalizado, com uma equipe
          dedicada disponível a qualquer momento. Estamos prontos para oferecer
          assistência tanto na utilização contínua da plataforma quanto na
          implementação do Intel Arch em sua operação, assegurando um suporte
          abrangente e eficiente.
        </p>
      </div>
    </div>
  );
}
export default Arch;
