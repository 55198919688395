import React, { useState } from "react";
import "../assets/css/style.css";
import "../assets/css/ourMedias.css";
import axios from "axios";

const OurMedias = () => {
  const clip = document.querySelectorAll(".clip");
  for (let i = 0; i < clip.length; i++) {
    clip[i].addEventListener("mouseenter", function (e) {
      clip[i].play();
    });
    clip[i].addEventListener("mouseout", function (e) {
      clip[i].pause();
    });
  }

  const [feedList, setFeedList] = React.useState([]);
  const [itemId0, setItemId0] = useState(null);
  const [itemId1, setItemId1] = useState(null);
  const [itemId2, setItemId2] = useState(null);
  const [itemId3, setItemId3] = useState(null);
  const [itemId4, setItemId4] = useState(null);
  const [itemId5, setItemId5] = useState(null);
  const [itemId6, setItemId6] = useState(null);
  const [itemId7, setItemId7] = useState(null);

  async function getInstaFeed() {
    const token = process.env.REACT_APP_INSTA_TOKEN;
    const fields = "media_url,media_type,permalink,caption, timestamp";
    const url = `https://graph.instagram.com/me/media?access_token=${token}&fields=${fields}`;

    const { data } = await axios.get(url);
    const images = data.data.filter(
      (item) =>
        item.media_type === "IMAGE" || item.media_type === "CAROUSEL_ALBUM"
    );
    const limitedImages = images.slice(0, 15); // Define o número máximo de imagens a serem exibidas (neste exemplo, 8)
    setFeedList(limitedImages);
    setItemId0(limitedImages[0].id);
    setItemId1(limitedImages[1].id);
    setItemId2(limitedImages[2].id);
    setItemId3(limitedImages[3].id);
    setItemId4(limitedImages[4].id);
    setItemId5(limitedImages[5].id);
    setItemId6(limitedImages[6].id);
    setItemId7(limitedImages[7].id);
  }

  React.useEffect(() => {
    getInstaFeed();
  }, []);

  return (
    <div id="our-medias">
      <div id="medias-title">
        <h1>Nossas mídias</h1>
        <div id="underline"></div>
        <p>
          Acompanhe a Singlec nas redes sociais e fique por dentro de todas as
          novidades
        </p>
      </div>
      <div id="feed">
        <div id="singlec">
          <a
            href="https://www.instagram.com/singlec_oficial/"
            target="_blank"
            rel="noreferrer"
          >
            <img src="https://singlec.com.br/imagens-site/favicon.png" alt="" />
          </a>
          <p>@singlec_oficial</p>
        </div>
        <div id="carousel">
          <div className="card-media1">
            {feedList.map((item) => {
              if (item.id === itemId0) {
                return (
                  <a
                    href={item.permalink}
                    target="_blank"
                    rel="noreferrer"
                    key={item.id}
                  >
                    {item.media_type === "IMAGE" ||
                    item.media_type === "CAROUSEL_ALBUM" ? (
                      <div className="card-media">
                        <img src={item.media_url} alt="" />
                        <div className="media-text">
                          <p>{item.caption}</p>
                        </div>
                      </div>
                    ) : (
                      <div className="=card-media">
                        <video
                          class="clip"
                          id="media-video"
                          type="video/mp4"
                          loop
                          Muted
                        >
                          <source src={item.media_url} />
                        </video>
                        <div className="media-text text-video">
                          <p>{item.caption}</p>
                        </div>
                      </div>
                    )}
                  </a>
                );
              }
              return null;
            })}
          </div>
          <div className="card-media2">
            {feedList.map((item) => {
              if (item.id === itemId1) {
                return (
                  <a
                    href={item.permalink}
                    target="_blank"
                    rel="noreferrer"
                    key={item.id}
                  >
                    {item.media_type === "IMAGE" ||
                    item.media_type === "CAROUSEL_ALBUM" ? (
                      <div className="card-media">
                        <img src={item.media_url} alt="" />
                        <div className="media-text">
                          <p>{item.caption}</p>
                        </div>
                      </div>
                    ) : (
                      <div className="=card-media">
                        <video className="clip" id="media-video" loop muted>
                          <source src={item.media_url} />
                        </video>
                        <div className="media-text text-video">
                          <p>{item.caption}</p>
                        </div>
                      </div>
                    )}
                  </a>
                );
              }
              return null;
            })}
          </div>
          <div className="card-media3">
            {feedList.map((item) => {
              if (item.id === itemId2) {
                return (
                  <a
                    href={item.permalink}
                    target="_blank"
                    rel="noreferrer"
                    key={item.id}
                  >
                    {item.media_type === "IMAGE" ||
                    item.media_type === "CAROUSEL_ALBUM" ? (
                      <div className="card-media">
                        <img src={item.media_url} alt="" />
                        <div className="media-text">
                          <p>{item.caption}</p>
                        </div>
                      </div>
                    ) : (
                      <div className="card-media">
                        <video className="clip" id="media-video" loop muted>
                          <source src={item.media_url} />
                        </video>
                        <div className="media-text text-video">
                          <p>{item.caption}</p>
                        </div>
                      </div>
                    )}
                  </a>
                );
              }
              return null;
            })}
          </div>
          <div className="card-media4">
            {feedList.map((item) => {
              if (item.id === itemId3) {
                return (
                  <a
                    href={item.permalink}
                    target="_blank"
                    rel="noreferrer"
                    key={item.id}
                  >
                    {item.media_type === "IMAGE" ||
                    item.media_type === "CAROUSEL_ALBUM" ? (
                      <div className="card-media">
                        <img src={item.media_url} alt="" />
                        <div className="media-text">
                          <p>{item.caption}</p>
                        </div>
                      </div>
                    ) : (
                      <div className="card-media">
                        <video className="clip" id="media-video" loop muted>
                          <source src={item.media_url} />
                        </video>
                        <div className="media-text text-video">
                          <p>{item.caption}</p>
                        </div>
                      </div>
                    )}
                  </a>
                );
              }
              return null;
            })}
          </div>
          <div className="card-media5">
            {feedList.map((item) => {
              if (item.id === itemId4) {
                return (
                  <a
                    href={item.permalink}
                    target="_blank"
                    rel="noreferrer"
                    key={item.id}
                  >
                    {item.media_type === "IMAGE" ||
                    item.media_type === "CAROUSEL_ALBUM" ? (
                      <div className="card-media">
                        <img src={item.media_url} alt="" />
                        <div className="media-text">
                          <p>{item.caption}</p>
                        </div>
                      </div>
                    ) : (
                      <div className="card-media">
                        <video className="clip" id="media-video" loop muted>
                          <source src={item.media_url} />
                        </video>
                        <div className="media-text text-video">
                          <p>{item.caption}</p>
                        </div>
                      </div>
                    )}
                  </a>
                );
              }
              return null;
            })}
          </div>
          <div className="card-media6">
            {feedList.map((item) => {
              if (item.id === itemId5) {
                return (
                  <a
                    href={item.permalink}
                    target="_blank"
                    rel="noreferrer"
                    key={item.id}
                  >
                    {item.media_type === "IMAGE" ||
                    item.media_type === "CAROUSEL_ALBUM" ? (
                      <div className="card-media">
                        <img src={item.media_url} alt="" />
                        <div className="media-text">
                          <p>{item.caption}</p>
                        </div>
                      </div>
                    ) : (
                      <div className="card-media">
                        <video className="clip" id="media-video" loop muted>
                          <source src={item.media_url} />
                        </video>
                        <div className="media-text text-video">
                          <p>{item.caption}</p>
                        </div>
                      </div>
                    )}
                  </a>
                );
              }
              return null;
            })}
          </div>
          <div className="card-media7">
            {feedList.map((item) => {
              if (item.id === itemId6) {
                return (
                  <a
                    href={item.permalink}
                    target="_blank"
                    rel="noreferrer"
                    key={item.id}
                  >
                    {item.media_type === "IMAGE" ||
                    item.media_type === "CAROUSEL_ALBUM" ? (
                      <div className="card-media">
                        <img src={item.media_url} alt="" />
                        <div className="media-text">
                          <p>{item.caption}</p>
                        </div>
                      </div>
                    ) : (
                      <div className="card-media">
                        <video className="clip" id="media-video" loop muted>
                          <source src={item.media_url} />
                        </video>
                        <div className="media-text text-video">
                          <p>{item.caption}</p>
                        </div>
                      </div>
                    )}
                  </a>
                );
              }
              return null;
            })}
          </div>
          <div className="card-media8">
            {feedList.map((item) => {
              if (item.id === itemId7) {
                return (
                  <a
                    href={item.permalink}
                    target="_blank"
                    rel="noreferrer"
                    key={item.id}
                  >
                    {item.media_type === "IMAGE" ||
                    item.media_type === "CAROUSEL_ALBUM" ? (
                      <div className="card-media">
                        <img src={item.media_url} alt="" />
                        <div className="media-text">
                          <p>{item.caption}</p>
                        </div>
                      </div>
                    ) : (
                      <div className="card-media">
                        <video class="clip" id="media-video" loop muted>
                          <source src={item.media_url} />
                        </video>
                        <div className="media-text text-video">
                          <p>{item.caption}</p>
                        </div>
                      </div>
                    )}
                  </a>
                );
              }
              return null;
            })}
          </div>
        </div>
        <div id="follow-us">
          <a
            href="https://www.instagram.com/singlec_oficial/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://singlec.com.br/imagens-site/instagram.png"
              alt=""
            />
            <p>Siga-nos no Instagram</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default OurMedias;
