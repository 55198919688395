import "../../assets/css/style.css";
import Nav from "../../components/Nav.jsx";
import Footer from "../../components/footer.jsx";
import Buttons from "../../components/Buttons.jsx";
import Arch from "../../layout/Arch.jsx";
import Business from "../../layout/Business.jsx";
import PlatformsHeader from "../../layout/PlatformsHeader.jsx";

function Platforms() {
  return (
    <div className="platforms">
      <Nav></Nav>
      <PlatformsHeader></PlatformsHeader>
      <Arch></Arch>
      <Business></Business>
      <Footer></Footer>
      <Buttons></Buttons>
    </div>
  );
}
export default Platforms;
