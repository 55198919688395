import "../assets/css/style.css";
import "../assets/css/why.css";
import { activeSlide3 } from "../assets/js/activeSlide";
import { useState, useEffect } from "react";

function Why() {
  const [slideState1, setSlideState1] = useState("");
  const [slideState2, setSlideState2] = useState("");
  const [slideState3, setSlideState3] = useState("");
  const [slideState4, setSlideState4] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", () =>
      activeSlide3(
        setSlideState1,
        setSlideState2,
        setSlideState3,
        setSlideState4
      )
    );
    return () =>
      window.removeEventListener("scroll", () =>
        activeSlide3(
          setSlideState1,
          setSlideState2,
          setSlideState3,
          setSlideState4
        )
      );
  }, []);

  return (
    <div id="why">
      <div id="why-title">
        <h1>
          Quatro motivos
          <br />
          para nos escolher
        </h1>
      </div>
      <div id="why-cards">
        <div id="why-card-1" className={slideState1}>
          <h2>Atendimento personalizado e flexível</h2>
          <p>
            Proporcionando uma experiência única que se adapta às necessidades
            individuais de cada cliente
          </p>
        </div>
        <div id="why-card-2" className={slideState2}>
          <h2>Tecnologia e inteligência artificial avançadas</h2>
          <p>
            Impulsionando a inovação e transformando a maneira como realizamos
            operações
          </p>
        </div>
        <div id="why-card-3" className={slideState3}>
          <h2>Contribuição para metas e controle operacional</h2>
          <p>Promovendo a excelência no desempenho e resultados consistentes</p>
        </div>
        <div id="why-card-4" className={slideState4}>
          <h2>Software Sob Medida</h2>
          <p>
            Personalização e desenvolvimento de software sob medida para suas
            necessidades.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Why;
