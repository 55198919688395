import "../assets/css/style.css";
import "../assets/css/business.css";

function Business() {
  return (
    <div id="business">
      <div className="business-title">
        <h1>Qualitysin</h1>
        <div className="underline3"></div>
        <h2>Para empresas certificadas</h2>
        <p>
          Compreendemos a importância de contar com uma plataforma de Gestão
          Integrada que atenda de forma abrangente às necessidades específicas
          da sua empresa.
        </p>
        <p>
          Dessa compreensão, nasceu o Qualitysin, uma plataforma completa
          projetada para cumprir com os compromissos nas áreas de qualidade,
          meio ambiente e responsabilidade social. Abrangendo normas como ISO
          9001:2015, ISO 14001:2015, ISO 28000:2009, ISO 45001:2018 e OEA
          (Operador Econômico Autorizado) da Receita Federal Brasileira.
        </p>
      </div>
      <div className="business-img1">
        <img
          src="https://singlec.com.br/imagens-site/login_qualitysin.png"
          alt=""
          width={"680px"}
        />
      </div>
      <div className="modules">
        <div className="modules-title">
          <h1>Conheça todos os módulos</h1>
          <div className="underline4"></div>
        </div>
        <div id="modules-cards">
          <div className="module1 module">
            <h2>Gestão de Não Conformidades</h2>
            <div className="description">
              <p>
                Identifique, registre e gerencie não conformidades de maneira
                eficaz, implementando medidas corretivas para aprimorar seu
                sistema de gestão.
              </p>
            </div>
            <div className="status">
              <p>Disponível</p>
            </div>
          </div>
          <div className="module2 module">
            <h2>Gestão Documental</h2>
            <div className="description">
              <p>
                Organize e controle toda a documentação relacionada às normas de
                certificação, garantindo fácil acesso e rastreabilidade.
              </p>
            </div>
            <div className="status">
              <p>Em breve</p>
            </div>
          </div>
          <div className="module3 module">
            <h2>Gestão de Licenças</h2>
            <div className="description">
              <p>
                Mantenha-se atualizado e em conformidade com as licenças
                essenciais para suas operações, evitando problemas legais e
                financeiros.
              </p>
            </div>
            <div className="status">
              <p>Em breve</p>
            </div>
          </div>
          <div className="module4 module">
            <h2>Controle de Mudanças</h2>
            <div className="description">
              <p>
                Gerencie alterações de forma controlada, avaliando seu impacto e
                assegurando que as atualizações sejam realizadas de maneira
                precisa.
              </p>
            </div>
            <div className="status">
              <p>Em breve</p>
            </div>
          </div>
          <div className="module5 module">
            <h2>Planejamento Estratégico</h2>
            <div className="description">
              <p>
                Trace e acompanhe seus planos estratégicos, alinhando-os com as
                normas de certificação para obter melhores resultados.
              </p>
            </div>
            <div className="status">
              <p>Em breve</p>
            </div>
          </div>
          <div className="module6 module">
            <h2>Gestão de Treinamentos</h2>
            <div className="description">
              <p>
                Gerencie os treinamentos de sua equipe, garantindo que todos
                estejam preparados para contribuir efetivamente com a excelência
                do seu negócio.
              </p>
            </div>
            <div className="status">
              <p>Em breve</p>
            </div>
          </div>
          <div className="module7 module">
            <h2>Gestão do PGR</h2>
            <div className="description">
              <p>
                Identifique, avalie e controle os riscos inerentes às atividades
                da sua empresa, garantindo uma abordagem proativa para a
                mitigação de riscos.
              </p>
            </div>
            <div className="status">
              <p>Em breve</p>
            </div>
          </div>
          <div className="module8 module">
            <h2>Gestão de Aspectos e Impactos</h2>
            <div className="description">
              <p>
                Avalie e controle os aspectos ambientais e impactos associados
                às suas operações, promovendo práticas sustentáveis.
              </p>
            </div>
            <div className="status">
              <p>Em breve</p>
            </div>
          </div>
        </div>
      </div>
      <div className="business-img2">
        <img
          src="https://singlec.com.br/imagens-site/nao_conformidade.png"
          alt=""
          width={"650px"}
        />
      </div>
      <div className="modules-text">
        <p>
          O Qualitysin destaca-se pela integração de Inteligência Artificial no
          módulo de gestão de não conformidades, permitindo a geração automática
          de riscos e a correção da descrição da não conformidade de forma
          autônoma.
        </p>
        <br />
        <p>
          Ao optar pelo Qualitysin, sua jornada em direção à certificação se
          tornará mais eficiente e assertiva, elevando a sua empresa no mercado
          e reforçando o seu compromisso inabalável com a excelência.
        </p>
      </div>
    </div>
  );
}
export default Business;
