import "../assets/css/style.css";
import "../assets/css/context.css";
import { React, useEffect, useState } from "react";
import { activeSlide } from "../assets/js/activeSlide";

function Context() {
  const [slideState1, setSlideState1] = useState("");
  const [slideState2, setSlideState2] = useState("");
  const [slideState3, setSlideState3] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", () =>
      activeSlide(setSlideState1, setSlideState2, setSlideState3)
    );
    return () =>
      window.removeEventListener("scroll", () =>
        activeSlide(setSlideState1, setSlideState2, setSlideState3)
      );
  }, []);

  return (
    <div id="context" className="slide">
      <div className="context-text">
        <h1>Contexto organizacional</h1>
        <p>
          Explorando Nossos Pilares: Missão, Valores e Visão Organizacional{" "}
        </p>
      </div>
      <div className="context-cards-1">
        <div id="context-card-1" className={slideState1}>
          <img
            src="https://singlec.com.br/imagens-site/icon_missao.png"
            width={"70px"}
            height={"70px"}
            alt=""
          />
          <h2>Missão</h2>
          <p>
            Desenvolver soluções inovadoras e autogerenciáveis, utilizando a
            inteligência artificial para simplificar processos e contribuir com
            o crescimento dos nossos clientes no mercado de trabalho.
          </p>
        </div>
        <div id="context-card-2" className={slideState2}>
          <img
            src="https://singlec.com.br/imagens-site/icon_valores.png"
            width={"70px"}
            height={"70px"}
            alt=""
          />
          <h2>Valores</h2>
          <p>
            Saúde mental no ambiente informatizado; Foco em resultados;
            Utilização consciente de recursos; Segurança das informações;
            Qualidade na prestação dos serviços; Governança corporativa.
          </p>
        </div>
        <div id="context-card-3" className={slideState3}>
          <img
            src="https://singlec.com.br/imagens-site/icon_visao.png"
            width={"65px"}
            height={"85px"}
            alt=""
          />
          <h2>Visão</h2>
          <p>
            Ser uma fonte de inspiração na área de tecnologia e inovação, sendo
            reconhecidos pela criação de sistemas autogerenciáveis.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Context;
