import "../assets/css/style.css";
import "../assets/css/nav.css";
import burguer from "../assets/icon/icon_menu.png";
import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { navColor } from "../assets/js/navColor";
import { burguerMenu } from "../assets/js/burguerMenu";

function Nav() {
  const [headerBackground, setHeaderBackground] = useState("");
  useEffect(() => {
    window.addEventListener("scroll", () => navColor(setHeaderBackground));
    return () =>
      window.removeEventListener("scroll", () => navColor(setHeaderBackground));
  }, []);

  return (
    <nav
      id="menu"
      style={{
        backgroundColor: headerBackground,
        width: "100%",
        transition: "background-color 2s ease-in-out",
      }}
    >
      <Link className="" to="/">
        <img
          src="https://singlec.com.br/imagens-publicas/logo_deitada_branca.png"
          width={"180px"}
          height={"28px"}
          alt=""
        />
      </Link>
      <button
        onClick={burguerMenu}
        id="burguer-btn"
        aria-label="Abrir Menu"
        aria-haspopup="true"
        aria-controls="menu"
        aria-expanded="false"
      >
        Menu
        <img src={burguer} width={"30px"} height={"30px"} alt="" />
      </button>
      <ul className="nav-menu" id="nav-menu">
        <li>
          <Link className="link" to="/">
            Início
          </Link>
        </li>
        <li>
          <Link className="link" to="/about">
            Sobre
          </Link>
        </li>
        <li>
          <Link className="link" to="/services">
            Serviços
          </Link>
        </li>
        <li>
          <Link className="link" to="/platforms">
            Plataformas
          </Link>
        </li>
        <li>
          <Link className="link" to="/faq">
            FAQ
          </Link>
        </li>
        <li>
          <a
            href="https://qualitysin.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="nav-btn">
              <p>Portal do cliente</p>
            </button>
          </a>
        </li>
      </ul>
    </nav>
  );
}
export default Nav;
