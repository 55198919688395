import "../../assets/css/style.css";
import FaqHeader from "../../layout/FaqHeader";
import Questions from "../../layout/Questions";
import Nav from "../../components/Nav";
import Footer from "../../components/footer";
import Buttons from "../../components/Buttons";

function FAQ() {
  return (
    <div className="faq">
      <Nav></Nav>
      <FaqHeader></FaqHeader>
      <Questions></Questions>
      <Footer></Footer>
      <Buttons></Buttons>
    </div>
  );
}
export default FAQ;
