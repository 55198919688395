import "../../assets/css/style.css";
import Nav from "../../components/Nav";
import Footer from "../../components/footer";
import Buttons from "../../components/Buttons";
import History from "../../layout/History";
import Context from "../../layout/Context";
import Policies from "../../layout/Policies";
import AboutHeader from "../../layout/AboutHeader";

function About() {
  return (
    <div className="about">
      <Nav></Nav>
      <AboutHeader></AboutHeader>
      <History></History>
      <Context></Context>
      <Policies></Policies>
      <Footer></Footer>
      <Buttons></Buttons>
    </div>
  );
}
export default About;
